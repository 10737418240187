import React from "react"

// CSS
import "./charts.scss"

// Themes
import { mainTheme } from "./themes"

// Charts
import { VictoryPie, VictoryTooltip } from "victory"

export default function Chart(props) {
  const { title, description, fullWidth } = props
  const labels = { y: "", x: "E-commerces" }

  const data = [
    { dataLabel: "Amazon", value: 13000, color: "black" },
    { dataLabel: "Ebay", value: 16500, color: "black" },
    { dataLabel: "Nike", value: 14250, color: "black" },
    { dataLabel: "ASOS", value: 19000, color: "black" },
  ]

  return (
    <div id="chart" className={fullWidth ? "full-width" : "half-width"}>
      <h4>{title ? title : ""}</h4>
      <h5>{description ? description : ""}</h5>
      <VictoryPie
        colorScale={["#fca311", "#ff5400", "#80ed99", "#d34600", "#6cc781"]}
        innerRadius={80}
        cornerRadius={0}
        padAngle={5}
        labelPosition={"centroid"}
        labelPlacement={"vertical"}
        data={data}
        x="dataLabel"
        y="value"
        sortKey="y"
        labels={({ datum }) => datum.earnings}
        labelComponent={<VictoryTooltip/>}
      />
    </div>
  )
}

// *
// * Colors
// *
const colors = [
  "#fca311",
  "#ff5400",
  "#80ed99",
  "#d34600",
  "#6cc781",
]

const baseColor = "#1e1e1e"
const grey = "#969696"
// *
// * Layout
// *
const baseProps = {
  width: 450,
  height: 350,
  padding: 60,
  colorScale: colors,
}
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: "'Quicksand', sans-serif",
  letterSpacing: "normal",
  fontSize: 14,
  padding: 10,
  fill: baseColor,
  stroke: "transparent",
}

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: "middle" }
// *
// * Strokes
// *
const strokeLinecap = "round"
const strokeLinejoin = "round"

const ColorPicker = (type, i) => {
    if (type === "colorScale" && i >= 0) return colors[i]
    else if (type === "black") return baseColor
    else if (type === "grey") return grey
    else return type
}

export const mainTheme = {
  area: {
    style: {
      data: {
        fill: baseColor,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: baseColor,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: { ...centeredLabelStyles, padding: 35, fontSize: 14 },
      grid: {
        fill: "none",
        stroke: "none",
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
    ...baseProps,
  },
  boxplot: {
    style: {
      max: { padding: 8, stroke: baseColor, strokeWidth: 1 },
      maxLabels: { ...baseLabelStyles, padding: 3 },
      median: { padding: 8, stroke: baseColor, strokeWidth: 1 },
      medianLabels: { ...baseLabelStyles, padding: 3 },
      min: { padding: 8, stroke: baseColor, strokeWidth: 1 },
      minLabels: { ...baseLabelStyles, padding: 3 },
      q1: { padding: 8, fill: grey },
      q1Labels: { ...baseLabelStyles, padding: 3 },
      q3: { padding: 8, fill: grey },
      q3Labels: { ...baseLabelStyles, padding: 3 },
    },
    boxWidth: 20,
    ...baseProps,
  },
  bar: {
    style: {
      data: {
        fill: ({ datum }) => datum.color ? ColorPicker(datum.color, datum._x - 1) : baseColor,
        padding: 8,
        strokeWidth: 0,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  chart: baseProps,
  errorbar: {
    borderWidth: 8,
    style: {
      data: {
        fill: "transparent",
        stroke: baseColor,
        strokeWidth: 2,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  histogram: {
    style: {
      data: {
        fill: grey,
        stroke: baseColor,
        strokeWidth: 2,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  group: {
    colorScale: colors,
    ...baseProps,
  },
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle",
      },
      labels: baseLabelStyles,
      title: { ...centeredLabelStyles, padding: 5 },
    },
  },
  line: {
    style: {
      data: {
        fill: "transparent",
        stroke: baseColor,
        strokeWidth: 2,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: "transparent",
        strokeWidth: 1,
      },
      labels: { ...baseLabelStyles, padding: 20 },
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
    innerRadius: 20
  },
  scatter: {
    style: {
      data: {
        fill: baseColor,
        stroke: "transparent",
        strokeWidth: 0,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  stack: {
    colorScale: colors,
    ...baseProps,
  },
  tooltip: {
    style: { ...baseLabelStyles, padding: 0, pointerEvents: "none" },
    flyoutStyle: {
      stroke: baseColor,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none",
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: {
    style: {
      data: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
      },
      labels: { ...baseLabelStyles, padding: 0, pointerEvents: "none" },
      flyout: {
        stroke: baseColor,
        strokeWidth: 1,
        fill: "#f0f0f0",
        pointerEvents: "none",
      },
    },
    ...baseProps,
  },
}

import React, { useRef } from "react"
import "../scss/blog-post.scss"

// Components
import Image from "../components/image/image"
import Layout from "../components/layout"
import TopSpacer from "../components/top-spacer/top-spacer"

// Charts
import BarChart from "../components/chart/bar-chart"
import PieChart from "../components/chart/pie-chart"

// SEO
import SEO from "../seo/main.js"
import BreadCrumb, { SmallBreadCrumbs } from "../seo/breadcrumb"

export default function Blog(props) {
  const blogPost = props.pageContext
  const crumbArray = [
    { title: "Blog", url: "/blog/" },
    { title: blogPost.title, url: blogPost.url },
  ]
  return (
    <>
      {blogPost ? (
        <Layout>
          <BlogPost
            blogPost={blogPost}
            location={props.location}
            array={crumbArray}
          />
        </Layout>
      ) : null}
    </>
  )
}

const BlogPost = props => {
  const { blogPost, location, array } = props
  const summaryRef = useRef(null)

  return (
    <>
      <SEO
        title={blogPost.seo_title}
        description={blogPost.seo_description}
        article
      />
      <TopSpacer />
      <div className="headline-area" key="headline-area">
        <h1>{blogPost.title}</h1>
      </div>
      <div className="summary-area" key="summary-area">
        <SmallBreadCrumbs array={array} />
        <ProfileArea title={blogPost.title} summaryRef={summaryRef} />
        <div className="featured">
          <div className="text">
            <h3>
              <b>In short — </b>
              {blogPost.title}
            </h3>
            <ul>
              {blogPost.header["in_short"].map((text, i) => (
                <li key={"in_short_" + i}>
                  <span>{text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="cta-text">
            {blogPost.header["cta"]}
            <br />
            <a href="#">Let me coach you and your team!</a>
          </div>
        </div>
        <div className="summary" ref={summaryRef}>
          <p>
            <span>
              <b>Foreword</b>
            </span>
          </p>
          {blogPost.header["foreword"].map((text, i) => (
            <p key={"foreword_" + i}>{text}</p>
          ))}
        </div>
      </div>
      <div className="body-area" key="body-area">
        <BlogBody content={blogPost.body} />
      </div>
      <BreadCrumb blogPost={blogPost} location={location} array={array} />
    </>
  )
}

function BlogBody({ content }) {
  const bodyArea = []
  content.forEach(block => {
    // Section header
    if (block.type === "section-header")
      bodyArea.push(<SectionHeaderBlock block={block} />)
    // Quote
    if (block.type === "quote") bodyArea.push(<QuoteBlock block={block} />)
    // Full width text
    if (block.type === "fullwidth-text")
      bodyArea.push(<FullTextBlock block={block} />)
    // Text + Graph
    if (block.type === "text-graph")
      bodyArea.push(<TextGraphBlock block={block} />)
    // Text + Text
    if (block.type === "text-text")
      bodyArea.push(<TextTextBlock block={block} />)
  })
  return bodyArea
}

/*
BUILDING BLOCKS
*/

const SectionHeaderBlock = ({ block }) => (
  <div className="section-headline" key={`section${block.id}`}>
    <h2>{block.text[0].text}</h2>
  </div>
)

const QuoteBlock = ({ block }) => (
  <div className="body-section full-width" key={`quote${block.id}`}>
    <div className="quote">
      <h5 className="text">{block.text}</h5>
      <h6 className="author">
        {block.authorLink ? (
          <a
            href={block.authorLink}
            target="_blank"
            rel="noreferrer"
            title="Go to source"
          >
            {block.author}
          </a>
        ) : (
          block.author
        )}
      </h6>
    </div>
  </div>
)

const FullTextBlock = ({ block }) => {
  const text = TextType(block.text, block.id)
  return (
    <div className="body-section full-width" key={`full_text${block.id}`}>
      {text}
    </div>
  )
}

const TextGraphBlock = ({ block }) => {
  const text = TextType(block.text, block.id)
  return (
    <div
      className="body-section full-width double-box"
      key={`text_graph${block.id}`}
    >
      <div className="half-width">{text}</div>
      {GraphType(block)}
    </div>
  )
}

const TextTextBlock = ({ block }) => {
  const textOne = TextType(
    block.text.filter(obj => obj.pos === 0),
    block.id
  )
  const textTwo = TextType(
    block.text.filter(obj => obj.pos === 1),
    block.id
  )
  return (
    <div
      className="body-section full-width double-box"
      key={`text_text${block.id}`}
    >
      <div className="half-width">{textOne}</div>
      <div className="half-width">{textTwo}</div>
    </div>
  )
}

/*
GRAPH
*/

const GraphType = block => {
  if (block.graph.type === "bar")
    return (
      <BarChart
        title={block.graph.title}
        description={block.graph.description}
        fullWidth={block.graph.fullWidth}
        data={block.graph.data}
      />
    )
  else if (block.graph.type === "pie")
    return (
      <PieChart
        title={block.graph.title}
        description={block.graph.description}
        fullWidth={block.graph.fullWidth}
        data={block.graph.data}
      />
    )
  else return null
}

/*
TEXT
*/

const TextType = (array, id) => {
  let outputArray = []
  let textArray = []
  let listArray = []
  let string = ""
  array.forEach((text, y) => {
    if (text.text !== "&nbsp;" && text.list_array.length === 0) {
      // If text tag changes — Push previous textArray
      if (y > 0 && array[y - 1].type !== text.type) {
        const textObject = TextTag(
          textArray,
          array[y - 1].type,
          array[y - 1].importance,
          id,
          y
        )
        outputArray.push(textObject)
        textArray = []
      }
      // Check if headlines don't match
      else if (
        y > 0 &&
        array[y - 1].type === "headline" &&
        text.type === "headline" &&
        array[y - 1].importance !== text.importance
      ) {
        outputArray.push(
          TextTag(textArray, array[y - 1].type, array[y - 1].importance, id, y)
        )
        textArray = []
      }
      // String
      string = text.text
      // Styling
      string = TextStyling(string, text, id, `${y}`)
      // Push the string
      textArray.push(string)
      // Last iteration — Push the end
      if (array.length === y + 1) {
        const textObject = TextTag(textArray, text.type, text.importance, id, y)
        outputArray.push(textObject)
      }
    } else if (text.list_array.length > 0) {
      /* ----- LIST ----- */
      // If text tag changes — Push previous textArray
      if (y > 0 && array[y - 1].type !== text.type) {
        const textObject = TextTag(
          textArray,
          array[y - 1].type,
          array[y - 1].importance,
          id,
          y
        )
        outputArray.push(textObject)
        textArray = []
      }
      text.list_array.forEach((list, x) => {
        // If list pos changes — Push previous listArray
        if (x > 0 && text.list_array[x - 1].pos !== list.pos) {
          const liObject = TextTag(listArray, "li", false, id, `${y}_${x}`)
          textArray.push(liObject)
          listArray = []
        }
        // String
        string = list.text
        // Styling
        string = TextStyling(string, list, id, `${y}_${x}`)
        // Push the string
        listArray.push(string)
        // Last iteration — Push the end
        if (text.list_array.length === x + 1) {
          const liObject = TextTag(listArray, "li", false, id, `${y}_${x}`)
          textArray.push(liObject)
          const listObject = TextTag(
            textArray,
            text.type,
            text.importance,
            id,
            y
          )
          outputArray.push(listObject)
          textArray = []
          listArray = []
        }
      })
    }
    // If only space break — Adds a space
    else if (text.text === "&nbsp;") {
      textArray.push(" ")
    }

    if (text.text.endsWith("&nbsp;")) {
      const textObject = TextTag(
        textArray,
        array[y - 1].type,
        array[y - 1].importance,
        id,
        y
      )
      outputArray.push(textObject)
      textArray = []
    }
  })
  return outputArray
}

const TextStyling = (string, obj, id, index) => {
  // Link
  if (obj.link && obj.link.active && obj.link.href.length > 0) {
    string = (
      <a
        key={`fullwidth_body_link${id}_${RandomID(index)}_a`}
        href={obj.link.href}
        target={obj.link.blank ? "_blank" : "_self"}
        rel={obj.link.blank ? "noreferrer" : ""}
        title={string}
      >
        {string}
      </a>
    )
  }
  // Text Bold
  if (obj.bold) {
    string = (
      <b key={`fullwidth_body_link${id}_${RandomID(index)}_b`}>{string}</b>
    )
  }
  // Italic
  if (obj.italic) {
    string = (
      <i key={`fullwidth_body_link${id}_${RandomID(index)}_i`}>{string}</i>
    )
  }
  // Underscore
  if (obj.underscore) {
    string = (
      <u key={`fullwidth_body_link${id}_${RandomID(index)}_u`}>{string}</u>
    )
  }
  return string
}

const TextTag = (string, type, importance, id, index) => {
  // Text
  if (type === "text") {
    return <p key={`fullwidth_body_link${id}_${RandomID(index)}_p`}>{string}</p>
  } else if (type === "headline" && importance === 1) {
    return (
      <h1 key={`fullwidth_body_link${id}_${RandomID(index)}_h1`}>{string}</h1>
    )
  } else if (type === "headline" && importance === 2) {
    return (
      <h2 key={`fullwidth_body_link${id}_${RandomID(index)}_h2`}>{string}</h2>
    )
  } else if (type === "headline" && importance === 3) {
    return (
      <h3 key={`fullwidth_body_link${id}_${RandomID(index)}_h3`}>{string}</h3>
    )
  } else if (type === "headline" && importance === 4) {
    return (
      <h4 key={`fullwidth_body_link${id}_${RandomID(index)}_h4`}>{string}</h4>
    )
  } else if (type === "headline" && importance === 5) {
    return (
      <h5 key={`fullwidth_body_link${id}_${RandomID(index)}_h5`}>{string}</h5>
    )
  } else if (type === "headline" && importance === 6) {
    return (
      <h6 key={`fullwidth_body_link${id}_${RandomID(index)}_h6`}>{string}</h6>
    )
  } else if (type === "dotlist") {
    return (
      <ul key={`fullwidth_body_link${id}_${RandomID(index)}_ul`}>{string}</ul>
    )
  } else if (type === "orderlist") {
    return (
      <ol key={`fullwidth_body_link${id}_${RandomID(index)}_ol`}>{string}</ol>
    )
  } else if (type === "li") {
    return (
      <li key={`fullwidth_body_link${id}_${RandomID(index)}_li`}>{string}</li>
    )
  }
}

const RandomID = string => {
  return string + "_" + Math.random().toString(36).substr(2, 9)
}

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

function ProfileArea({ title, summaryRef }) {
  const [dimensions, setDimensions] = React.useState(
    summaryRef.current ? summaryRef.current.clientHeight : false
  )
  // Check the height of the element
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions(summaryRef.current.clientHeight)
    }, 400)

    window.addEventListener("resize", debouncedHandleResize)

    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })
  // Returns the height of the element when Ref loads
  React.useEffect(() => {
    setDimensions(summaryRef.current.clientHeight)
  }, [summaryRef.current])

  // Returns the dimensions
  const DimensionCalc = () => {
    const calc = dimensions - 520 > 220 ? dimensions - 520 : 220
    return calc + "px"
  }

  return (
    <div
      className="profile-area"
      style={{ height: `${!dimensions ? "100vh" : DimensionCalc()}` }}
    >
      <div className="inner">
        <div className="text">
          <p><b>Author: Johannes Eklund</b></p>
          <p>Digital nomad who have worked with some of the largest retailers and brands in the nordics. He is a former senior at one of the world's best performance marketing agencies.</p>
        </div>
      <Image
          className="author-image"
          src="profile-small.jpg"
          alt="Profile picture of "
          title={"The author of blog post — " + title}
          width="50"
          height="50"
        />
      </div>
    </div>
  )
}

import React from "react"

// CSS
import "./charts.scss"

// Themes
import { mainTheme } from "./themes"

// Charts
import { VictoryBar, VictoryChart, VictoryAxis } from "victory"

export default function Chart(props) {

  const { title, description, fullWidth, data } = props;
  const labels = {y: "", x: "E-commerces"}

  return (
    <div id="chart" className={fullWidth ? "full-width" : "half-width"}>
    <h4>{title ? title : ""}</h4>
    <h5>{description ? description : ""}</h5>
    <VictoryChart domainPadding={45} theme={mainTheme} animate={{
  duration: 2000
}}>
      <VictoryBar data={data} x="dataLabel" y="value" />
      <VictoryAxis label={labels.x} />
      <VictoryAxis dependentAxis label={labels.y} />
    </VictoryChart>
    </div>
  )
}

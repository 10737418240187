import React from "react"
import Helmet from "react-helmet"
import SiteMeta from "../seo/site-meta"

export default function BreadCrumb({ location, array }) {
  // Breadcrumb Array
  array = [{ title: "Home", url: "/" }, ...array]

  // Root Domain
  const { url } = SiteMeta()

  // LD-JSON string
  const BreadcrumbString = () => {
    let string = ""
    array.forEach((crumb, i) => {
      if (i > 0) string += ","

      string += `{
                "@type": "ListItem",
                "position": ${i + 1},
                "name": ${'"' + crumb.title + '",'}
                "item": ${'"' + url}${
        i === array.length - 1 ? location.pathname : crumb.url
      }${'"'}
    }`
    })
    return string
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [${BreadcrumbString()}]
          }
    `}</script>
      </Helmet>
      <div className="breadcrumbs">
        {array.map((crumb, i) => {
          if (i === array.length - 1)
            return <div className="active">{crumb.title}</div>
          else
            return (
              <>
                <div>
                  <a href={url + crumb.url}>{crumb.title}</a>
                </div>
                <div>{" > "}</div>
              </>
            )
        })}
      </div>
    </>
  )
}

export function SmallBreadCrumbs ({ array }) {
  // Breadcrumb Array
  array = [{ title: "Home", url: "/" }, ...array]

  // Root Domain
  const { url } = SiteMeta()

  return (
    <>
      <div className="breadcrumbs small">
        {array.map((crumb, i) => {
          if (i === array.length - 1)
            return <div className="active">{crumb.title}</div>
          else
            return (
              <>
                <div>
                  <a href={url + crumb.url}>{crumb.title}</a>
                </div>
                <div>{" / "}</div>
              </>
            )
        })}
      </div>
    </>
  )
}
